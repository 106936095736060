import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import { iconTools, iconUser, iconDesign, iconEmail, iconLinkedin, iconGithub, iconMedium, iconTwitter } from '../util/svgs.js'

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="index">
      <section className="hero">
        <div className="content">
          <div className="hero__profile">
            <StaticImage
              className="hero__profile--image"
              src="../images/profile.jpg"
              alt="A profile of Roi"
              placeholder="blurred"
              quality={95}
            />
            <div className="hero__profile--right">
              <h1>Roi Livne</h1>
              <div className="divider" />
              <h2>Web Developer</h2>
            </div>
          </div>
          <div className="hero__essence">
            <div className="icon--container icon--big" >
              {iconTools()}
              <h4 className="font-italic">Full Stack</h4>
            </div>
            <div className="icon--container icon--big" >
              {iconUser()}
              <h4 className="font-italic">User Centered Design</h4>
            </div>
            <div className="icon--container icon--big" >
              {iconDesign()}
              <h4 className="font-italic">Web &amp; App</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="content">
          <h3>Let's get to know each other</h3>
          <div className="divider" />
          <div className="section-text">
            <p>My name is Roi and I'm a <span className="primary-text">Full Stack</span> Developer.</p>
            <p>I'm highly enthusiastic about creating <span className="primary-text">UX</span> focused websites and interfaces.</p>
            <p>My main focuses are <span className="primary-text">React</span> and <span className="primary-text">Node</span>.</p>
          </div>
          <div className="section-cta">
            <p>I'm also experienced in <span className="primary-text">instructing</span> and <span className="primary-text">managing</span>.</p>
            <p>Outside of work I enjoy diving, drawing, gaming and Cross-Fit.</p>
          </div>
        </div>
      </section>
      <section className="blog">
        <div className="content">
          <h3>Blog</h3>
          <div className="divider" />
          <div className="section-text">
            <p><span className="primary-text">Giving back</span> is important to me.</p>
            <p>Whenever I find something <span className="primary-text">new</span>, <span className="primary-text">exciting</span> or <span className="primary-text">helpful</span> I try to cover it
          in my blog.</p>
          </div>
          <div className="section-cta">
            <p>You can read more here, or find me on <span className="primary-text">Medium</span>!</p>
          </div>
          <button className="btn btn--section" onClick={() => { navigate("/blog") }}>Blog</button>
        </div>
      </section>
      <section className="contact">
        <div className="content">
          <h3>Want to know more?</h3>
          <div className="divider" />
          <h2 className="font-main font-reg">Let's connect!</h2>
          <div className="contact__icons">
            <a className="icon--container icon--med icon-hover" href="https://github.com/Roi-Livne" >
              {iconGithub()}
              <h4>Github</h4>
            </a>
            <a className="icon--container icon--med  icon-hover" href="mailto:roilivne14@gmail.com">
              {iconEmail()}
              <h4>Email</h4>
            </a>
            <a className="icon--container icon--med icon-hover" href="https://www.linkedin.com/in/roi-livne/">
              {iconLinkedin()}
              <h4>Linkedin</h4>
            </a>
            <a className="icon--container icon--med icon-hover" href="https://roilivne14.medium.com/">
              {iconMedium()}
              <h4>Medium</h4>
            </a>
            <a className="icon--container icon--med icon-hover" href="https://twitter.com/LivneRoi">
              {iconTwitter()}
              <h4>Twitter</h4>
            </a>
          </div>
        </div>
      </section>
    </div>
  </Layout >
)

export default IndexPage
